@keyframes pulse
  0%
    opacity: 0.75
  50%
    opacity: 1.0
  100%
    opacity: 0.75

@keyframes pulseMin
  0%
    opacity: 0.25
  50%
    opacity: 0.5
  100%
    opacity: 0.25

@keyframes pulseBig
  0%
    opacity: 0
  10%
    opacity: 1
  50%
    opacity: 1
  90%
    opacity: 1
  100%
    opacity: 0