@import '../../../../ui-styles/src/lib/sass/yo-variables'
@import '../../../../ui-styles/src/lib/sass/yo-mixins'
@import '../../../../../node_modules/bootstrap/scss/functions'
@import '../../../../../node_modules/bootstrap/scss/variables'
@import '../../../../ui-styles/src/lib/sass/yo-breakpoints'

.centeredPanel
  position: absolute
  width: 90vw
  height: 90vh
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center
  margin: 0 5vw
  left: auto
  right: auto
  @media screen and (max-width: $mobileActive)
     height: 100vh

