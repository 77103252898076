
.nav-link
  word-break: break-all

.pagination
  .page-item
    &.active
      span
        z-index: revert !important

.btn
  span:not(.badge)
    color: inherit
  &.btn-primary
    color: $white
  &.btn-success
    color: $white
  &.btn-info
    color: $white

.form-control, .form-select, .form-check-input
  background-color: $white


@media screen and (max-width: $mobileActive)
  .btn
    font-size: 14px !important
    padding: 2px 5px
    word-break: break-all
    overflow-x: hidden
    line-break: strict
    white-space: nowrap
    margin-bottom: 2px
@media screen and (max-width: $mobileActive)
  .nav-tabs
    flex-wrap: nowrap !important
    overflow-x: scroll
    .nav-item
      white-space: nowrap !important
