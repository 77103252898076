@import '../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../node_modules/bootstrap/scss/functions'
@import '../../../../../node_modules/bootstrap/scss/variables'
@import '../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.buildEntry
  display: flex
  width: 100%
  justify-content: space-between
  padding: 10px 15px
  position: relative
  &:hover
    cursor: pointer
  &:after
    height: 1px
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    content: ""
    background: linear-gradient(90deg, $gray-100 0%, $gray-100 5%, $gray-400 100%)

  .groupOne
    display: flex
    flex-direction: row

    .buildId
      font-weight: $font-weight-bold
      margin-left: 10px
      span
        font-size: 12px
      small
        display: block

  .groupTwo
    .buildDate
      font-size: 12px

@media screen and (min-width: $mobileActive)
  .buildEntry
    max-width: 800px
    margin: 0 auto

