@import '../../../../ui-styles/src/lib/sass/yo-variables'
@import '../../../../../node_modules/bootstrap/scss/functions'
@import '../../../../../node_modules/bootstrap/scss/variables'

.header
  font-size: 1.2rem
  display: block
  font-weight: $font-weight-bold
  i
    margin-right: 10px
    color: $primary
    font-size: 20px

  &.sm
    font-size: 1rem
    i
      font-size: 18px

  &.lg
    font-size: 1.5rem
    i
      font-size: 25px

//.feature-header-sm
//  display: block
//  font-weight: $font-weight-bold
//  i
//    margin-right: 10px
//    color: $primary
//    font-size: 18px
//
//.feature-header-md
//  font-size: 1.2rem
//  display: block
//  font-weight: $font-weight-bold
//  i
//    margin-right: 10px
//    color: $primary
//    font-size: 20px
//
//.feature-header-lg
//  font-size: 1.5rem
//  display: block
//  font-weight: $font-weight-bold
//  i
//    margin-right: 10px
//    color: $primary
//    font-size: 25px
