
span.text
  color: inherit

.pulse
  animation : pulse 1s infinite

.link
  color: $primary
  cursor: pointer

.cursor-pointer
  &:hover
    cursor: pointer

.scrollableList
  width: 100%
  max-height: 73vh
  overflow-y: auto
  text-align: left
  @media screen and (max-width: $mobileActive)
    max-height: 30vh


//.view-title
//  display: block
//  padding: 10px 0
//  text-transform: capitalize
//  font-size: 26px
//  margin-bottom: 50px
//  border-bottom: 1px solid $gray-400

//.sub-title
//  display: block
//  padding: 10px 0
//  text-transform: capitalize
//  font-size: 20px
//  margin: 10px 0 30px

//.heading-md
//  margin: 15px 0 25px 0
//  display: block
//  font-weight: $font-weight-bold
//  font-size: 120%
//  i
//    margin-right: 10px
//    color: $primary
//    font-size: 22px
//
//.heading-sm
//  margin: 25px 0 15px 0
//  display: block
//  font-weight: $font-weight-bold
//  i
//    margin-right: 10px
//    color: $primary
//    font-size: 18px

//a.settings-link
//  color: $gray-600
//  font-size: 18px
//  &:hover
//    color: $primary

//.text-yellow
//  color: $yellow !important

//.custom-drop-down-caret
//  width: 20px
//  height: 20px
//  position: relative
//  display: inline-block
//  &:before
//    font-family: "Font Awesome 5 Free"
//    font-weight: 900
//    content: "\f0da"
//    position: absolute
//    left: 0
//    top: 0px
//    color: $gray-500
//    font-size: 20px
//  &.active
//    &:before
//      content: "\f0d7" !important

//.feature-header-sm
//  display: block
//  font-weight: $font-weight-bold
//  i
//    margin-right: 10px
//    color: $primary
//    font-size: 18px

//.feature-header-md
//  font-size: 1.2rem
//  display: block
//  font-weight: $font-weight-bold
//  i
//    margin-right: 10px
//    color: $primary
//    font-size: 20px

//.feature-header-lg
//  font-size: 1.5rem
//  display: block
//  font-weight: $font-weight-bold
//  i
//    margin-right: 10px
//    color: $primary
//    font-size: 25px
