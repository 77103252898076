.noAccess
  display: flex
  flex-direction: column
  align-content: center
  align-items: center
  justify-content: center
  justify-items: center
  width: 100%

  i
    font-size: 150px
    margin: 50px -55px 50px 0
