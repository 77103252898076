@import '../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../node_modules/bootstrap/scss/functions'
@import '../../../../../node_modules/bootstrap/scss/variables'
@import '../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'


.buildHeading
  margin-top: 10px
  font-size: 12px
  font-weight: $font-weight-bold

.buildText
  font-size: 16px
  text-transform: capitalize

.buildDataBox
  @media screen and (min-width: $mobileActive)
    background-color: $white
    box-shadow: $box-shadow-sm
    padding: 10px
    border-radius: $border-radius-desktop-lg
    margin-bottom: 20px
    margin-top: 20px

