@import '../../../../ui-styles/src/lib/sass/yo-variables'
@import '../../../../../node_modules/bootstrap/scss/functions'
@import '../../../../../node_modules/bootstrap/scss/variables'
@import '../../../../ui-styles/src/lib/sass/yo-breakpoints'

div
  \:global(.table-responsive)
    display: flex
    flex-direction: column
    background-color: transparent
    margin-top: 20px

table.table
  background-color: $white
  margin-left: auto
  margin-right: auto
  white-space: nowrap !important
  border-radius: $border-radius-desktop-lg !important
  max-height: 70vh
  overflow-y: scroll
  thead
    border-radius: $border-radius-desktop-lg
    background-color: $gray-100
    tr
      height: 52px
      th
        padding-top: 5px !important
        padding-bottom: 5px !important
        vertical-align: middle !important
        position: sticky
        top: 0
    tr:first-child
      border-top: 0 !important
      border-radius: $border-radius-desktop-lg
      th
        border-top: 0 !important
      th:first-child
        border-top: 0 !important
        border-top-left-radius: $border-radius-desktop-lg
      th:last-child
        border-top: 0 !important
        border-top-right-radius: $border-radius-desktop-lg
  tbody
    border-top: 0 !important
    tr
      border-top: 8px solid $background-color-desktop
      td
        padding: 7px 10px !important
        vertical-align: middle !important
        font-size: 0.95rem
        &:global(.max-width)
          max-width: 500px
          white-space: initial !important
        //&.status-td
        //  &.archived
        //    color: $gray-900
      &:global(.archived)
        background-color: $warning
        &:hover
          background-color: $orange
    tr:last-child
      td
        border-bottom: 0 !important
      td:first-child
        border-bottom: 0 !important
        border-bottom-left-radius: $border-radius-desktop-lg
      td:last-child
        border-bottom: 0 !important
        border-bottom-right-radius: $border-radius-desktop-lg
  tr
    &:global(.linked-row)
      &:hover
        cursor: pointer
    //.expand-tr
    //  i
    //    transition: 0.2s
    //  &.collapsed
    //    i
    //      transform: rotate(180deg)
    td
      padding-top: 3px !important
      padding-bottom: 3px !important
      //&.linked-data
      //  &:hover
      //    cursor: pointer
  table
    tr
      border-top: none !important

@media screen and (max-width: $mobileActive)
  table.table
    min-width: 100% !important
    font-size: 0.8rem
    th,td
      font-size: 0.8rem
      li, span, strong
        font-size: 0.8rem
