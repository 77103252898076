@import '../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../node_modules/bootstrap/scss/functions'
@import '../../../../../node_modules/bootstrap/scss/variables'

.navbar
  background-color: $dark-nav

  .back
    color: $white
    margin-left: -15px
    width: 35px
    text-align: center
    font-size: 18px
    padding: 0 5px 0 10px
    &:hover
      cursor: pointer

  .buildDate
    margin-right: 0
    margin-left: auto
    color: $white
