@import "../../../../libs/ui-styles/src/lib/sass/yo.sass" // everything from core

body, div, a, p, li, dd, span, small, strong
  -webkit-font-smoothing: antialiased
  font-family: 'Roboto', sans-serif !important

body, div, a, p, li, dd, span, strong, dl, dd, dt
  font-size: 14px

small
  font-size: 11px

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus
  box-shadow: none !important
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus
  box-shadow: none !important
.btn-link:not(:disabled):not(.disabled):active:focus, .btn-link:not(:disabled):not(.disabled).active:focus, .show > .btn-link.dropdown-toggle:focus
  box-shadow: none !important
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus
  box-shadow: none !important

