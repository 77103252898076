@import "../../../../ui-styles/src/lib/sass/yo-variables"
@import "../../../../ui-styles/src/lib/sass/yo-mixins"
@import '../../../../../node_modules/bootstrap/scss/functions'
@import '../../../../../node_modules/bootstrap/scss/variables'

.customModal
  \:global(.modal-dialog)
    \:global(.modal-content)
      border-radius: $border-radius-desktop-lg
      border: none !important
      @include dropShadowLg()
      \:global(.modal-header)
        border-top-left-radius: $border-radius-desktop-lg
        border-top-right-radius: $border-radius-desktop-lg
        background-color: $gray-100
      \:global(.modal-body)
        border: none !important
        padding-top: 40px
        padding-bottom: 40px
      \:global(.modal-footer)
        border-bottom-left-radius: $border-radius-desktop-lg
        border-bottom-right-radius: $border-radius-desktop-lg
        background-color: $primary !important
        border-color: shade-color($primary, 10%) !important
        border: none !important


