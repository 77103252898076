@import '../../../ui-styles/src/lib/sass/yo-variables'
@import '../../../ui-styles/src/lib/sass/yo-mixins'
@import '../../../../node_modules/bootstrap/scss/functions'
@import '../../../../node_modules/bootstrap/scss/variables'
@import '../../../ui-styles/src/lib/sass/yo-breakpoints'

.signInLogo
  @media screen and (max-width: $mobileActive)
    width: 100% !important

.panel
  margin: 0
  width: 100vw
  height: 100vh
  background-color: $gray-300 !important

  .row
    height: 10vh
    justify-content: center

@media screen and (max-width: $mobileActive)
  body
    background-color: $gray-300 !important
