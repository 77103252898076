@import '../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../node_modules/bootstrap/scss/functions'
@import '../../../../../node_modules/bootstrap/scss/variables'
@import '../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'


.searchForm
  background-color: $dark-nav
  padding: 10px 15px
  \:global(.input-group)
    margin-bottom: 0 !important
    @media screen and (min-width: $mobileActive)
      width: 500px
      margin: 0 auto
