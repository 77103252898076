@import '../../../../ui-styles/src/lib/sass/yo-variables'
@import '../../../../../node_modules/bootstrap/scss/_functions.scss'
@import '../../../../../node_modules/bootstrap/scss/_variables.scss'

.toast
  opacity: 1 !important
  min-width: 350px
  z-index: 100000000000000
  \:global(.toast-header)
    background-color: $gray-800

    strong, button
      color: $gray-100
