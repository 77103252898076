@import '../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../node_modules/bootstrap/scss/functions'
@import '../../../../../node_modules/bootstrap/scss/variables'

.buildIcon
  color: $white
  font-size: 24px
  background-color: $primary
  border-radius: 50%
  width: 38px
  height: 38px
  text-align: center
  display: inline-flex
  flex-direction: column
  justify-content: center

  &:global(.intraday)
    background-color: $orange
  &:global(.production)
    background-color: $red
  &:global(.nightly)
    background-color: $dark
  &:global(.playtest)
    background-color: $pink
  &:global(.testing)
    background-color: $teal
  &:global(.adhoc)
    background-color: $indigo
