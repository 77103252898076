body
  min-height: 100vh
  background: $background-color-desktop
  margin: 0
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

body, div, a, p, li, dd, small, strong
  -webkit-font-smoothing: antialiased
  color: $gray-700

@media screen and (max-width: $mobileActive)
  body, div, a, p, li, dd, span, strong, table, td, th, button, .btn
    font-size: 0.9rem

strong
  font-weight: $font-weight-bold
  color: inherit

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

input
  &[type = checkbox]
    &:hover
      cursor: pointer

button
  &:focus
    outline: none !important
a
  text-decoration: none !important
//.no-shadow
//  box-shadow: none !important
//  text-shadow: none !important
