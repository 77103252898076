@import 'yo-variables'

@mixin dropShadowLg
  box-shadow: 10px 10px 10px $shadow-color

@mixin dropShadowSm
  box-shadow: 5px 5px 5px $shadow-color

@mixin background-gradient
  background: linear-gradient(315deg, $gray-start 0%, $gray-end)

@mixin lightenBackgroundColor($color, $percentage)
  background-color: tint-color($color, $percentage)
