@import '../../../../ui-styles/src/lib/sass/yo-variables'
@import '../../../../../node_modules/bootstrap/scss/functions'
@import '../../../../../node_modules/bootstrap/scss/variables'
@import '../../../../ui-styles/src/lib/sass/yo-mixins'
@import '../../../../ui-styles/src/lib/sass/yo-breakpoints'

.collapsePanel
  position: relative
  + div
    margin-top: 30px
    @media screen and (max-width: $mobileActive)
      margin-top: 0
  @media screen and (max-width: $mobileActive)
    border-bottom: 1px solid $primary
    margin-top: -1px
  .collapsePanelToggle
    display: none
    z-index: 1000
    @media screen and (max-width: $mobileActive)
      display: block
      position: absolute
      bottom: -20px
      left: 0
      right: 0
      margin: 0 auto
      width: 50px
      height: 20px
      border-bottom-left-radius: 10px
      border-bottom-right-radius: 10px
      border-top-left-radius: 0
      border-top-right-radius: 0
      background-color: $primary
      box-shadow: $box-shadow
      z-index: 0
      &:before
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        margin: 0 auto
        width: 100%
        font-family: "Font Awesome 5 Free", sans-serif
        font-weight: 900
        text-align: center
        color: #fff
        content: "\f078"
        -webkit-font-smoothing: antialiased
.collapsePanelToggle
  .collapsePanelContent:global(.show) ~ &
    &:before
      content: "\f077"

