@import '../../../../ui-styles/src/lib/sass/yo-variables'
@import '../../../../../node_modules/bootstrap/scss/functions'
@import '../../../../../node_modules/bootstrap/scss/variables'

.header
  margin: 25px 0 15px 0
  display: block
  font-weight: $font-weight-bold
  i
    margin-right: 10px
    color: $primary
    font-size: 18px
