@import '../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../node_modules/bootstrap/scss/functions'
@import '../../../../../node_modules/bootstrap/scss/variables'
@import '../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.navbar
  background-color: $dark-nav

.brandLink
  text-decoration: none !important
  color: $white !important
  font-size: 1.25rem
.projectLogo
  height: 30px !important
