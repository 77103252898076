@import '../../../ui-styles/src/lib/sass/yo-variables'
@import '../../../../node_modules/bootstrap/scss/functions'
@import '../../../../node_modules/bootstrap/scss/variables'
@import '../../../ui-styles/src/lib/sass/yo-mixins'
@import '../../../ui-styles/src/lib/sass/yo-breakpoints'

.yoHeader
  z-index: 100
  height: 55px
  justify-content: space-between
  background-color: $fb-blue-darkest
  box-shadow: 0 2px 10px rgba(0,0,0,0.2)
  transition: 0.2s
  position: relative

  \:global(.navbar)
    padding: 5px 1rem !important

  @media screen and (max-width: $mobileActive)
    height: auto
    \:global(.navbar)
      display: flex
      flex-direction: column !important

  .yoHeaderToggle
    display: none
    @media screen and (max-width: $mobileActive)
      display: block
      position: absolute
      bottom: -20px
      left: 0
      right: 0
      margin: 0 auto
      width: 50px
      height: 20px
      border-top-left-radius: 0
      border-top-right-radius: 0
      border-bottom-left-radius: 10px
      border-bottom-right-radius: 10px
      background-color: $fb-blue-darkest
      box-shadow: $box-shadow
      &:before
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        margin: 0 auto
        width: 100%
        font-family: "Font Awesome 5 Free", sans-serif
        font-weight: 900
        text-align: center
        color: #fff
        content: "\f078"
        -webkit-font-smoothing: antialiased
      &:not(.collapsed)
        background-color: $fb-blue-darkest

  .yoHeaderLogo
    width: 140px

  .yoHeaderUserImage
    width: 30px
    height: 30px
    border-radius: 50%
    margin-left: 7px

  \:global(.navbar-nav)
    @media screen and (max-width: $mobileActive)
      flex-direction: column !important
      align-items: center
    .navItemLeftBorder
      border-left: 1px solid $gray-700
      margin-left: 20px
      @media screen and (max-width: $mobileActive)
        border-left: 0
        margin-left: 0

    \:global(.nav-link)
      color: $gray-300 !important
      span
        color: $gray-300 !important
      &.active
        background-color: transparent !important
        color: $primary !important
      &:hover
        background-color: transparent !important
        color: $primary !important
      & span
        color: $gray-500

    \:global(.dropdown)
      \:global(.dropdown-menu)
        background-color: $white
        border: 0
        border-radius: 10px
        @include dropShadowSm
        \:global(.dropdown-item)
          color: $gray-600 !important
          &:global(.active)
            background-color: transparent !important
            color: $primary !important
          &:hover
            background-color: transparent !important
            color: $primary !important
.yoHeaderToggle
  \:global(.collapse):global(.show) ~ &
    &:before
      content: "\f077"

.yoBackgroundImageWrapper
  position: relative
  height: calc(100% - 55px)
  min-height: calc(100vh - 55px)

  .yoBackgroundImage
    z-index: -1
    content: ""
    top: 0
    left: 0
    bottom: 0
    width: 100%
    height: 100%
    background-size: cover
    background-attachment: fixed
    background-repeat: no-repeat
    filter: grayscale(100%)
    opacity: 0.1
    position: absolute
