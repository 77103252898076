@import '../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../node_modules/bootstrap/scss/functions'
@import '../../../../../node_modules/bootstrap/scss/variables'
@import '../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.footer
  display: flex
  flex-direction: row
  position: fixed
  bottom: 0
  left: 0
  width: 100%
  padding: 0
  height: 60px
  justify-content: flex-start

  \:global(.dropup), .clear-search
    height: 100%
    flex-grow: 1

    button:global(.btn)
      width: 100%
      height: 100%
      border-radius: 0
      border: 0
      text-transform: uppercase
      i
        font-size: 22px

    \:global(.dropdown-menu)
      background-color: $primary
      border-color: shade-color($primary, 10%)
      min-width: 100%

      \:global(.dropdown-item)
        color: $white

@media screen and (min-width: $mobileActive)
  .footer
    max-width: 800px
    margin: 0 auto
    left: 0
    right: 0
    \:global(.dropup)
      \:global(.dropdown-menu)
        width: 100%


