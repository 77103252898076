@import '../../../../ui-styles/src/lib/sass/yo-variables'
@import '../../../../../node_modules/bootstrap/scss/functions'
@import '../../../../../node_modules/bootstrap/scss/variables'

label
  .header
    display: block
    font-weight: $font-weight-bold
    i
      margin-right: 10px
      color: $primary
      font-size: 18px

  .body
    font-size: 22px
    font-weight: $font-weight-lighter
    max-height: 37px
    overflow: hidden
    display: block
