@import "../../../../../libs/ui-styles/src/lib/sass/yo-variables"
@import '../../../../../node_modules/bootstrap/scss/functions'
@import '../../../../../node_modules/bootstrap/scss/variables'
@import '../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.artifactAccordion
  \:global(.accordion-item)
    border: 0
  \:global(.card-header)
    display: flex
    flex-direction: row
    align-content: center
    justify-items: center
    width: 100%
    justify-content: center
    border-bottom: 0
    background-color: transparent
    //padding: 0
  \:global(.accordion-button)
    padding: 0 20px 0 0
  .accordionTitleLink
    width: 80px
    text-align: right

  .accordionToggle
    display: flex
    width: 100%
    justify-content: space-between
    padding: 0
    position: relative
    &:after
      height: 1px
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      content: ""
      background: linear-gradient(90deg, $gray-100 0%, $gray-100 5%, $gray-400 100%)

    .accordionTitleText
      display: flex
      flex-direction: row
      align-items: center
      text-transform: capitalize

      .deviceIcon
        font-size: 22px
        margin-right: 5px

        &:global(.android)
          color: $green
        &:global(.ios)
          color: $pink

      small
        margin: 3px 0 0 3px

        &:global(.Development)
          color: $green
        &:global(.Staging)
          color: $orange
        &:global(.Production)
          color: $red
        &:global(.QAS)
          color: $info

  \:global(.accordion-body)
    //padding: 0

  .accordionBody
    padding: 15px
    background-color: $white

  @media screen and (min-width: $mobileActive)
    max-width: 800px
    margin: 0 auto
