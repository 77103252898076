//Bootstrap overrides
$container-max-widths   : (xl: 1370px)
$font-family-sans-serif : 'Roboto', sans-serif
$body-bg: #f6f7f9

//radius
$border-radius-desktop-lg : 20px
$border-radius-desktop-sm : 10px

//sizes
$publisher-nav-width      : 270px
//.vw95
//  max-width: 95vw
//  width: 95vw
//.vw90
//  max-width: 95vw
//  width: 90vw

//background gradients
$gray-start : rgba(246,246,250,1)
$gray-end   : rgba(187,197,216,1)

//custom-colors
$blue-pale                : #889bd9
$blue-palest              : #c8ddeb
$shadow-color             : rgba(0,0,0,0.1)
$dark-nav                 : #43464f
$darkest-nav              : #313439
$fb-blue-darkest          : #061e33
$fb-blue-dark             : #1a3043
$background-color-desktop : #f6f7f9

//softer color pallet
$blue                     : #498edb
$indigo:  #6610f2 !default
$purple:  #6f42c1 !default
$pink:    #e83e8c !default
$red                      : #e3513e
$orange                   : #f5b849
$yellow                   : #ffe066
$green                    : #7cdf91
$teal:    #20c997 !default
$cyan                     : #63a2bb

//uses original color variable values
$success                  : #0cc30e
$warning                  : #f5b849
$danger                   : #dc3545
$info                     : #17a2b8

//opacity
$opacity-100: opacity(0.1)
$opacity-300: opacity(0.3)
$opacity-500: opacity(0.5)
$opacity-700: opacity(0.7)
$opacity-900: opacity(0.9)

//.opacity-100
//  opacity: $opacity-100
//.opacity-300
//  opacity: $opacity-300
//.opacity-500
//  opacity: $opacity-500
//.opacity-700
//  opacity: $opacity-700
//.opacity-900
//  opacity: $opacity-900
